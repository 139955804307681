<template>
  <div v-if="numberHits > 0">
    <div class="ProductSection">
      <!-- Get both pagination and currentRefinements for
                 checking against isFirstPage and items.length -->
      <AisPagination v-slot="{ isFirstPage }">
        <AisCurrentRefinements v-slot="{ items }">
          <BlockCollapse
            :title="
              `${$tc('searchresults-refrence', numberHits, {
                count: numberHits.toLocaleString(),
              })} ${currentReference}`
            "
            :expanded="isFirstPage && items.length === 0"
          >
            <div class="Grid Grid--products">
              <ProductCard
                v-for="hit in hits"
                :key="hit.objectID"
                class="Grid__item"
                :product="hit"
              />
            </div>
          </BlockCollapse>
        </AisCurrentRefinements>
      </AisPagination>
    </div>
  </div>
</template>

<script>
import { AisCurrentRefinements, AisPagination } from 'vue-instantsearch';
import { ProductCard } from '@components/Cards';
import { BlockCollapse } from '@components/UI';

export default {
  name: 'AisReferenceHitsWrapper',

  components: {
    AisCurrentRefinements,
    AisPagination,
    BlockCollapse,
    ProductCard,
  },

  props: {
    numberHits: {
      required: true,
      type: Number,
    },
    hits: {
      required: true,
    },
    currentReference: {
      required: true,
      type: String,
    },
  },
};
</script>
