<template>
  <InstantSearch
    :enable-routing="true"
    :contexts="contexts.split(',')"
    :filters="getFilters"
    :optional-filters="optionalFilters"
    :analytics-tags="contexts.split(',')"
  >
    <Page drawer-button-id="pagesidebar_openButton">
      <template #top>
        <div v-if="showFilterInLeftMenu" class="TopPosCarSearch">
          <BlockCollapse :title="$t('carsearch.licenseplate')" :expanded="true">
            <CarSearch
              lookup=""
              :hide-headline="true"
              :car-url="settings.legacy.urlCar"
              :show-manuel="true"
              :group="settings.legacy.groupId"
            />
          </BlockCollapse>
        </div>

        <Top :title="title" :controls="controls" />
      </template>

      <template #drawerButton="{openDrawer}">
        <CurrentRefinements v-slot="{ totalRefinementAmount }">
          <Button
            id="pagesidebar_openButton"
            class="Page__drawerButton"
            :size="sizes.FULL"
            :count="totalRefinementAmount"
            :count-position="countPosition.INLINE"
            @click="openDrawer"
          >
            <i class="fas fa-sliders-h" aria-hidden="true"></i>{{ $t('show-filters') }}
          </Button>
        </CurrentRefinements>
      </template>

      <template #sidebar-button>
        <i class="fas fa-sliders-h" aria-hidden="true"></i>{{ $t('show-filters') }}
      </template>

      <template #sidebar="{ isActive }">
        <Sidebar
          :controls="controls"
          :is-group="isGroup"
          :drawer-is-active="isActive"
          :show-filter-in-left-menu="showFilterInLeftMenu"
        />
      </template>

      <template #default>
        <ReferenceHits
          v-slot="{ nbHits: referenceNbHits, hits: referenceHits, currentReference }"
          @change="onReferenceChange"
          @status="onReferenceStatus"
        >
          <AisStateResults
            v-if="settings.testmode"
            v-slot="{ results: { nbHits, exhaustiveNbHits } }"
          >
            <p v-if="nbHits" style="font-size: 16px; font-weight: bold;">
              {{ $tc('found-products', nbHits, { count: nbHits.toLocaleString() }) }}
              <span v-if="!exhaustiveNbHits" style="font-weight: normal; font-size: 12px;">
                (Antallet er estimeret)
              </span>
            </p>
            <p v-else style="font-size: 20px; color: grey; text-align: center;">
              {{ $t('no-results') }}
            </p>
          </AisStateResults>

          <AisReferenceHitsWrapper
            :number-hits="referenceNbHits"
            :hits="referenceHits"
            :current-reference="currentReference"
          />

          <div class="ProductSection ProductSection">
            <template v-if="referenceNbHits > 0">
              <h3 class="ProductSection__info">{{ $t('searchresults') }}:</h3>
            </template>

            <div v-show="isStalled">
              <SearchIndicator :label="$t('searching')" />
            </div>

            <Hits
              v-slot="{ hits, trackClick, trackConvension }"
              hit-key="item_number"
              class="Grid Grid--products"
              @noHits="e => (referenceNbHits === 0 ? onNoHits(e) : null)"
            >
              <ProductCard
                v-for="hit in hits"
                :key="hit.objectID"
                class="Grid__item"
                :enable-amount="settings.xalmode"
                :product="hit"
                button-location="Søge side"
                @view="trackClick(hit.objectID)"
                @addToCart="trackConvension(hit.objectID, 'Product Added to Cart')"
              />
            </Hits>
          </div>
        </ReferenceHits>

        <PaginationContainer
          v-slot="{
            currentRefinement,
            nbPages,
            pages,
            isFirstPage,
            isLastPage,
            refine,
            createURL,
          }"
        >
          <ul
            v-if="pages.length > 1"
            :class="[
              'Pagination',
              nbPages > 3 && currentRefinement === 0 && 'Pagination--hide-last-on-first-page',
              nbPages > 3 &&
                currentRefinement !== 0 &&
                currentRefinement < nbPages - 2 &&
                'Pagination--hide-last',
              nbPages > 3 && currentRefinement > nbPages - 3 && 'Pagination--hide-first',
            ]"
          >
            <li v-if="!isFirstPage" class="Pagination__item">
              <a
                :href="createURL(0)"
                @click.prevent="
                  () => {
                    scrollUp();
                    refine(0);
                  }
                "
              >
                ‹‹
              </a>
            </li>
            <li v-if="!isFirstPage" class="Pagination__item">
              <a
                :href="createURL(currentRefinement - 1)"
                @click.prevent="
                  () => {
                    scrollUp();
                    refine(currentRefinement - 1);
                  }
                "
              >
                ‹
              </a>
            </li>
            <li
              v-for="page in pages"
              :key="page"
              :class="['Pagination__item', page === currentRefinement && 'is-selected']"
            >
              <a
                :href="createURL(page)"
                @click.prevent="
                  () => {
                    scrollUp();
                    refine(page);
                  }
                "
              >
                {{ page + 1 }}
              </a>
            </li>
            <li v-if="!isLastPage" class="Pagination__item">
              <a
                :href="createURL(currentRefinement + 1)"
                @click.prevent="
                  () => {
                    scrollUp();
                    refine(currentRefinement + 1);
                  }
                "
              >
                ›
              </a>
            </li>
            <li v-if="!isLastPage" class="Pagination__item">
              <a
                :href="createURL(nbPages - 1)"
                @click.prevent="
                  () => {
                    scrollUp();
                    refine(nbPages - 1);
                  }
                "
              >
                ››
              </a>
            </li>
          </ul>
        </PaginationContainer>
      </template>
    </Page>
  </InstantSearch>
</template>

<script>
import { gsap } from 'gsap/all';
import { CurrentRefinements } from '@containers/Algolia';
import { BlockCollapse } from '@components/UI';
import { AisStateResults } from 'vue-instantsearch';

import CarSearch from '@components/CarSearch';

import { Button, sizes, countPosition } from '@components/Buttons';
// import { Suggestions } from '@components/Search';
import { ProductCard } from '@components/Cards';
import { InstantSearch, Hits, ReferenceHits } from '@components/Search';
import { SearchIndicator } from '@components/UI';

import Top from './components/Top';
import Sidebar from './components/Sidebar';
import AisReferenceHitsWrapper from './components/AisReferenceHitsWrapper';

import Page from '@layouts/Page';

import { PaginationContainer } from '@containers/Algolia';

import { EventBus } from '@services/eventBus';
import eventRoles from '@services/eventRoles';

import { routing, searchClient } from '@algolia';
import tracking from '@tracking';
import settings from '@settings';

export default {
  name: 'SearchScene',

  components: {
    BlockCollapse,

    AisStateResults,

    InstantSearch,
    Hits,
    ReferenceHits,

    Page,
    ProductCard,
    Button,

    SearchIndicator,

    Top,
    Sidebar,
    AisReferenceHitsWrapper,
    CurrentRefinements,

    PaginationContainer,

    CarSearch,
  },

  props: {
    optionalFilters: {
      type: String,
      default: '',
    },
    filters: {
      type: String,
      default: '',
    },
    contexts: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    controls: {
      // Type is set to string because of a legacy source
      type: String,
      default: 'menu,brand,price,type',
    },
    isGroup: {
      type: Boolean,
      required: true,
    },
    showFilterInLeftMenu: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      eventRoles,

      ruleItems: [],
      settings,

      isStalled: true,

      sizes,
      countPosition,

      nbSuggestions: 0,
      nbGroupHits: 0,
      nbRefrences: 0,
      refrenceStatus: '',
    };
  },
  computed: {
    redirectTo() {
      const ruleItem = this.ruleItems.find((item, index) => {
        return Boolean(item.redirect);
      });
      return ruleItem ? ruleItem.redirect : null;
    },
    getFilters() {
      return this.filters;
    },
    hasAnyHits() {
      return (
        this.totalHits > 0 || this.nbSuggestions > 0 || this.nbGroupHits > 0 || this.nbRefrences > 0
      );
    },
  },

  created() {
    // Listen for this component role event
    EventBus.$on(eventRoles.SEARCH_PRODUCTS_COMPONENT, isStalled => {
      this.isStalled = isStalled;
    });
  },

  methods: {
    openDrawer() {
      this.$refs['drawer'].open();
    },

    scrollUp() {
      gsap.to(window, { duration: 0.8, delay: 0.2, scrollTo: { y: this.$el, offsetY: 80 } });
    },

    onSuggestionsChange({ nbSuggestions }) {
      this.nbSuggestions = nbSuggestions;
    },
    onGroupChange({ nbHits }) {
      this.nbGroupHits = nbHits;
    },
    onReferenceChange({ nbHits }) {
      this.nbRefrences = nbHits;
    },
    onReferenceStatus({ status }) {
      this.refrenceStatus = status;
    },
    onNoHits(e) {
      if (!this.hasAnyHits && this.refrenceStatus === 'updated' && e && e.query) {
        tracking.addEvent('SoegV4', 'NullHits', e.query);
      }
    },
  },

  provide() {
    return {
      showFilterInLeftMenu: () => this.showFilterInLeftMenu,
    };
  },
};
</script>

<style lang="scss" scoped>
.TopPosCarSearch {
  display: block;

  @media screen AND (min-width: $laptopS) {
    display: none;
  }
}

.cut-text {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 260px;

  white-space: nowrap;
  display: inline-block;
  vertical-align: bottom;
}

.Pagination {
  text-align: center;

  &--hide-first {
    & > li {
      &:nth-child(3) {
        display: none;
      }
    }
  }
  &--hide-last-on-first-page {
    & > li {
      &:nth-child(5) {
        display: none;
      }
    }
  }
  &--hide-last {
    & > li {
      &:nth-child(7) {
        display: none;
      }
    }
  }

  &__item {
    display: inline-block;

    &:first-child {
      a {
        border-radius: $button-border-radius 0 0 $button-border-radius;
      }
    }

    :last-child a {
      border-radius: 0 $button-border-radius $button-border-radius 0;
    }

    a {
      position: relative;
      margin-bottom: 0;
      padding: 0.8em 1.5em;
      display: inline-block;

      outline: none !important;
      text-align: center;
      vertical-align: middle;
      text-decoration: none;
      cursor: pointer;
      user-select: none;
      touch-action: manipulation;

      text-transform: uppercase;
      transition: all 130ms ease-out;
      outline: none;

      text-decoration: none !important;
      font-size: 14px;
      white-space: nowrap;

      background-color: $color-white;
      color: #666666;
      border: 1px solid #efefef;
      text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.06);
      box-shadow: 0 1.2px 0 rgba(0, 0, 0, 0.04);
      margin-left: -1px;
    }

    &.is-selected {
      color: $color-white;

      a {
        background-color: $color-prim-blue;
        color: $color-white;
        font-weight: bold;
      }
    }
  }
}
</style>
