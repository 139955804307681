var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('InstantSearch',{attrs:{"enable-routing":true,"contexts":_vm.contexts.split(','),"filters":_vm.getFilters,"optional-filters":_vm.optionalFilters,"analytics-tags":_vm.contexts.split(',')}},[_c('Page',{attrs:{"drawer-button-id":"pagesidebar_openButton"},scopedSlots:_vm._u([{key:"top",fn:function(){return [(_vm.showFilterInLeftMenu)?_c('div',{staticClass:"TopPosCarSearch"},[_c('BlockCollapse',{attrs:{"title":_vm.$t('carsearch.licenseplate'),"expanded":true}},[_c('CarSearch',{attrs:{"lookup":"","hide-headline":true,"car-url":_vm.settings.legacy.urlCar,"show-manuel":true,"group":_vm.settings.legacy.groupId}})],1)],1):_vm._e(),_c('Top',{attrs:{"title":_vm.title,"controls":_vm.controls}})]},proxy:true},{key:"drawerButton",fn:function(ref){
var openDrawer = ref.openDrawer;
return [_c('CurrentRefinements',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var totalRefinementAmount = ref.totalRefinementAmount;
return [_c('Button',{staticClass:"Page__drawerButton",attrs:{"id":"pagesidebar_openButton","size":_vm.sizes.FULL,"count":totalRefinementAmount,"count-position":_vm.countPosition.INLINE},on:{"click":openDrawer}},[_c('i',{staticClass:"fas fa-sliders-h",attrs:{"aria-hidden":"true"}}),_vm._v(_vm._s(_vm.$t('show-filters'))+" ")])]}}],null,true)})]}},{key:"sidebar-button",fn:function(){return [_c('i',{staticClass:"fas fa-sliders-h",attrs:{"aria-hidden":"true"}}),_vm._v(_vm._s(_vm.$t('show-filters'))+" ")]},proxy:true},{key:"sidebar",fn:function(ref){
var isActive = ref.isActive;
return [_c('Sidebar',{attrs:{"controls":_vm.controls,"is-group":_vm.isGroup,"drawer-is-active":isActive,"show-filter-in-left-menu":_vm.showFilterInLeftMenu}})]}},{key:"default",fn:function(){return [_c('ReferenceHits',{on:{"change":_vm.onReferenceChange,"status":_vm.onReferenceStatus},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var referenceNbHits = ref.nbHits;
var referenceHits = ref.hits;
var currentReference = ref.currentReference;
return [(_vm.settings.testmode)?_c('AisStateResults',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_results = ref.results;
var nbHits = ref_results.nbHits;
var exhaustiveNbHits = ref_results.exhaustiveNbHits;
return [(nbHits)?_c('p',{staticStyle:{"font-size":"16px","font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.$tc('found-products', nbHits, { count: nbHits.toLocaleString() }))+" "),(!exhaustiveNbHits)?_c('span',{staticStyle:{"font-weight":"normal","font-size":"12px"}},[_vm._v(" (Antallet er estimeret) ")]):_vm._e()]):_c('p',{staticStyle:{"font-size":"20px","color":"grey","text-align":"center"}},[_vm._v(" "+_vm._s(_vm.$t('no-results'))+" ")])]}}],null,true)}):_vm._e(),_c('AisReferenceHitsWrapper',{attrs:{"number-hits":referenceNbHits,"hits":referenceHits,"current-reference":currentReference}}),_c('div',{staticClass:"ProductSection ProductSection"},[(referenceNbHits > 0)?[_c('h3',{staticClass:"ProductSection__info"},[_vm._v(_vm._s(_vm.$t('searchresults'))+":")])]:_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isStalled),expression:"isStalled"}]},[_c('SearchIndicator',{attrs:{"label":_vm.$t('searching')}})],1),_c('Hits',{staticClass:"Grid Grid--products",attrs:{"hit-key":"item_number"},on:{"noHits":function (e) { return (referenceNbHits === 0 ? _vm.onNoHits(e) : null); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hits = ref.hits;
var trackClick = ref.trackClick;
var trackConvension = ref.trackConvension;
return _vm._l((hits),function(hit){return _c('ProductCard',{key:hit.objectID,staticClass:"Grid__item",attrs:{"enable-amount":_vm.settings.xalmode,"product":hit,"button-location":"Søge side"},on:{"view":function($event){return trackClick(hit.objectID)},"addToCart":function($event){return trackConvension(hit.objectID, 'Product Added to Cart')}}})})}}],null,true)})],2)]}}])}),_c('PaginationContainer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var currentRefinement = ref.currentRefinement;
        var nbPages = ref.nbPages;
        var pages = ref.pages;
        var isFirstPage = ref.isFirstPage;
        var isLastPage = ref.isLastPage;
        var refine = ref.refine;
        var createURL = ref.createURL;
return [(pages.length > 1)?_c('ul',{class:[
            'Pagination',
            nbPages > 3 && currentRefinement === 0 && 'Pagination--hide-last-on-first-page',
            nbPages > 3 &&
              currentRefinement !== 0 &&
              currentRefinement < nbPages - 2 &&
              'Pagination--hide-last',
            nbPages > 3 && currentRefinement > nbPages - 3 && 'Pagination--hide-first' ]},[(!isFirstPage)?_c('li',{staticClass:"Pagination__item"},[_c('a',{attrs:{"href":createURL(0)},on:{"click":function($event){$event.preventDefault();return (function () {
                  _vm.scrollUp();
                  refine(0);
                })($event)}}},[_vm._v(" ‹‹ ")])]):_vm._e(),(!isFirstPage)?_c('li',{staticClass:"Pagination__item"},[_c('a',{attrs:{"href":createURL(currentRefinement - 1)},on:{"click":function($event){$event.preventDefault();return (function () {
                  _vm.scrollUp();
                  refine(currentRefinement - 1);
                })($event)}}},[_vm._v(" ‹ ")])]):_vm._e(),_vm._l((pages),function(page){return _c('li',{key:page,class:['Pagination__item', page === currentRefinement && 'is-selected']},[_c('a',{attrs:{"href":createURL(page)},on:{"click":function($event){$event.preventDefault();return (function () {
                  _vm.scrollUp();
                  refine(page);
                })($event)}}},[_vm._v(" "+_vm._s(page + 1)+" ")])])}),(!isLastPage)?_c('li',{staticClass:"Pagination__item"},[_c('a',{attrs:{"href":createURL(currentRefinement + 1)},on:{"click":function($event){$event.preventDefault();return (function () {
                  _vm.scrollUp();
                  refine(currentRefinement + 1);
                })($event)}}},[_vm._v(" › ")])]):_vm._e(),(!isLastPage)?_c('li',{staticClass:"Pagination__item"},[_c('a',{attrs:{"href":createURL(nbPages - 1)},on:{"click":function($event){$event.preventDefault();return (function () {
                  _vm.scrollUp();
                  refine(nbPages - 1);
                })($event)}}},[_vm._v(" ›› ")])]):_vm._e()],2):_vm._e()]}}])})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }