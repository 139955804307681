<template>
  <div>
    <div v-if="showFilterInLeftMenu" class="SidebarCarSearch">
      <BlockCollapse :title="$t('carsearch.licenseplate')" :expanded="true">
        <CarSearch
          lookup=""
          :hide-headline="true"
          :car-url="settings.legacy.urlCar"
          :show-manuel="true"
          :group="settings.legacy.groupId"
        />
      </BlockCollapse>
    </div>

    <AlgoliaCurrentRefinements v-slot="{ refinements }">
      <BlockCollapse
        v-if="drawerIsActive && refinements.length > 0"
        title="Filtre"
        :expanded="true"
      >
        <CurrentRefinements />
      </BlockCollapse>
    </AlgoliaCurrentRefinements>

    <HierarchicalMenu v-if="hasControl('menu') && !isGroup" />
    <TypeFilter v-if="hasControl('type')" :searchable="settings.testmode" />
    <PriceFilter v-if="settings.mode !== 'b2b' && hasControl('price')" />

    <!--
        Specifications filter
        ---------------------
        Just now the brands filter is excluded manually.
        In time excludedAttrs should be defined serveside
      -->
    <SpecificationsFilter :excluded-attrs="[{ sheet: '99009910', fields: ['20'] }]" />

    <BrandFilter v-if="hasControl('brand')" />
    <TestmodeFilter v-if="settings.testmode" />

    <ProfileFilter v-if="settings.testmode" />
    <InternalGroupFilter v-if="settings.testmode" />
    <ParentFilter v-if="settings.testmode" />
  </div>
</template>

<script>
import settings from '@settings';
import { AisHierarchicalMenu } from 'vue-instantsearch';
import { BlockCollapse } from '@components/UI';
import {
  HierarchicalMenu,
  SpecificationsFilter,
  TestmodeFilter,
  BrandFilter,
  PriceFilter,
  TypeFilter,
  ProfileFilter,
  ParentFilter,
  InternalGroupFilter,
} from '@components/Search';
import { CurrentRefinements } from '@components/Algolia';
import { CurrentRefinements as AlgoliaCurrentRefinements } from '@containers/Algolia';

import CarSearch from '@components/CarSearch';

export default {
  name: 'SearchSceneSidebar',
  components: {
    BlockCollapse,
    HierarchicalMenu,
    SpecificationsFilter,
    TestmodeFilter,
    InternalGroupFilter,
    TypeFilter,
    ProfileFilter,
    BrandFilter,
    PriceFilter,
    ParentFilter,
    CurrentRefinements,
    AisHierarchicalMenu,
    AlgoliaCurrentRefinements,
    CarSearch,
  },
  props: {
    controls: {
      // Type is set to string because of a legacy source
      type: String,
      default: 'menu,brand,price,type',
    },
    isGroup: {
      type: Boolean,
      required: true,
    },
    drawerIsActive: {
      default: false,
      type: Boolean,
    },
    showFilterInLeftMenu: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      settings,
    };
  },

  computed: {
    controlsArray() {
      if (this.controls) {
        return this.controls.split(',');
      } else {
        return ['menu', 'brand', 'price', 'type'];
      }
    },
    hasControl() {
      return controlId => this.controlsArray.indexOf(controlId) !== -1;
    },
  },
};
</script>

<style lang="scss">
.SidebarCarSearch {
  display: block;

  @media screen AND (max-width: $laptopSMax) {
    display: none;
  }
}
</style>
