<template>
  <div>
    <SearchBox
      v-slot="{
        query,
        refine,
        stalled,
      }"
      show-loading-indicator
      :delay="360"
      :role="eventRoles.SEARCH_PRODUCTS_COMPONENT"
    >
      <h2 v-if="title" class="SearchTop__title">{{ title }}</h2>
      <h2 v-else-if="hasControl('status') && query" class="SearchTop__title">
        {{ $t('searchresults-query') }} <span class="cut-text">"{{ query }}"</span>
      </h2>
      <h2 v-else-if="hasControl('status')" class="SearchTop__title">{{ $t('search') }}</h2>

      <div
        :class="[
          'Input Input--search Input--large',
          { 'is-working': stalled },
          { 'is-focus': isSearchFocus },
        ]"
        @mouseleave="blurSearch(600)"
        @mouseover="focusSearch(false, 200)"
      >
        <div class="Input__fieldWrap">
          <div class="Input__spinner">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" class="Spinner">
              <circle
                xmlns="http://www.w3.org/2000/svg"
                cx="50"
                cy="50"
                fill="none"
                stroke="#ff708e"
                stroke-width="8"
                r="30"
                stroke-dasharray="70"
              ></circle>
            </svg>
          </div>
          <i class="Input__icon far fa-search"></i>

          <input
            ref="input"
            :value="query"
            :placeholder="$t('search')"
            type="text"
            name="search"
            autocomplete="off"
            spellcheck="false"
            role="combobox"
            @input="refine($event.currentTarget.value)"
            @focus="onInputFocus"
            @blur="onInputBlur"
            @keydown.enter.prevent="$event.currentTarget.blur()"
          />
          <button class="Input__clear js-clear"></button>
        </div>
        <span class="Input__message js-message"></span>
        <!-- Only visible if it has suggestions -->
        <div v-show="nbSuggestions > 0" class="Input__dropdown">
          <!-- <Suggestions
            :title="$t('suggestions') + ':'"
            :query="query"
            @change="onSuggestionsChange"
            @query-selected="
              q => {
                refine(q, true);
                focusSearch(true);
              }
            "
          ></Suggestions> -->
        </div>
      </div>
    </SearchBox>

    <div class="SearchBar">
      <div>
        <CurrentRefinements class="Refinements" />
      </div>

      <div class="SearchBar__sortBy">
        <span>Sortér efter</span>
        <AisSortBy
          :items="[
            { value: algoliaIndices.products, label: $t('popularity') },
            { value: algoliaIndices.products + '--priceAsc', label: $t('sort-price-asc') },
            { value: algoliaIndices.products + '--priceDesc', label: $t('sort-price-desc') },
          ]"
          :class-names="{
            'ais-SortBy': 'Input',
          }"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { AisSortBy } from 'vue-instantsearch';
import { SearchBox } from '@components/Search';
import SearchBar from '@components/Search/SearchBar';
import eventRoles from '@services/eventRoles';
import settings from '@settings';

import { CurrentRefinements } from '@components/Algolia';

const productFilterData = window['productFilterData'];

export default {
  name: 'SearchSceneTop',

  components: {
    SearchBox,
    CurrentRefinements,
    SearchBar,
    AisSortBy,
  },

  props: {
    title: {
      type: String,
      default: '',
    },
    controls: {
      // Type is set to string because of a legacy source
      type: String,
      default: 'menu,brand,price',
    },
  },

  data() {
    return {
      eventRoles,

      inputBlueTimeout: undefined,
      inputFocusTimeout: undefined,

      isSearchFocus: false,
      nbSuggestions: 0,
      algoliaIndices: settings.search.indices,
      attributes:
        productFilterData !== null && productFilterData !== undefined
          ? Object.keys(productFilterData)
              .map(key =>
                Object.keys(productFilterData[key]).map(child => 'sheets.' + key + '.' + child),
              )
              .flat()
          : [],
    };
  },

  computed: {
    controlsArray() {
      if (this.controls) {
        return this.controls.split(',');
      } else {
        return ['menu', 'brand', 'price'];
      }
    },
    hasControl() {
      return controlId => this.controlsArray.indexOf(controlId) !== -1;
    },
  },

  methods: {
    onInputFocus() {
      this.isSearchFocus = true;
    },
    onInputBlur() {
      this.blurSearch(200);
    },
    blurSearch(delay) {
      clearTimeout(this.inputFocusTimeout);
      clearTimeout(this.inputBlueTimeout);
      if (delay) {
        this.inputBlueTimeout = setTimeout(() => {
          if (document.activeElement !== this.$refs['input']) {
            this.isSearchFocus = false;
          }
        }, delay);
      } else {
        this.isSearchFocus = false;
      }
    },
    focusSearch(elFocus, delay) {
      clearTimeout(this.inputBlueTimeout);
      clearTimeout(this.inputFocusTimeout);
      if (delay) {
        this.inputFocusTimeout = setTimeout(() => {
          this.focusSearch(elFocus);
        }, delay);
        return;
      }

      if (elFocus) this.$refs['input'].focus();
      this.isSearchFocus = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.SearchTop__title {
  font-size: 22px;
  @include minBreakpoint(768) {
    font-size: 28px;
  }
}
.Refinements {
  display: none;
  @media (min-width: $laptopS) {
    display: block;
  }
}
</style>
