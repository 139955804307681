var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('SearchBox',{attrs:{"show-loading-indicator":"","delay":360,"role":_vm.eventRoles.SEARCH_PRODUCTS_COMPONENT},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var query = ref.query;
    var refine = ref.refine;
    var stalled = ref.stalled;
return [(_vm.title)?_c('h2',{staticClass:"SearchTop__title"},[_vm._v(_vm._s(_vm.title))]):(_vm.hasControl('status') && query)?_c('h2',{staticClass:"SearchTop__title"},[_vm._v(" "+_vm._s(_vm.$t('searchresults-query'))+" "),_c('span',{staticClass:"cut-text"},[_vm._v("\""+_vm._s(query)+"\"")])]):(_vm.hasControl('status'))?_c('h2',{staticClass:"SearchTop__title"},[_vm._v(_vm._s(_vm.$t('search')))]):_vm._e(),_c('div',{class:[
        'Input Input--search Input--large',
        { 'is-working': stalled },
        { 'is-focus': _vm.isSearchFocus } ],on:{"mouseleave":function($event){return _vm.blurSearch(600)},"mouseover":function($event){return _vm.focusSearch(false, 200)}}},[_c('div',{staticClass:"Input__fieldWrap"},[_c('div',{staticClass:"Input__spinner"},[_c('svg',{staticClass:"Spinner",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 100 100"}},[_c('circle',{attrs:{"xmlns":"http://www.w3.org/2000/svg","cx":"50","cy":"50","fill":"none","stroke":"#ff708e","stroke-width":"8","r":"30","stroke-dasharray":"70"}})])]),_c('i',{staticClass:"Input__icon far fa-search"}),_c('input',{ref:"input",attrs:{"placeholder":_vm.$t('search'),"type":"text","name":"search","autocomplete":"off","spellcheck":"false","role":"combobox"},domProps:{"value":query},on:{"input":function($event){return refine($event.currentTarget.value)},"focus":_vm.onInputFocus,"blur":_vm.onInputBlur,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return $event.currentTarget.blur()}}}),_c('button',{staticClass:"Input__clear js-clear"})]),_c('span',{staticClass:"Input__message js-message"}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.nbSuggestions > 0),expression:"nbSuggestions > 0"}],staticClass:"Input__dropdown"})])]}}])}),_c('div',{staticClass:"SearchBar"},[_c('div',[_c('CurrentRefinements',{staticClass:"Refinements"})],1),_c('div',{staticClass:"SearchBar__sortBy"},[_c('span',[_vm._v("Sortér efter")]),_c('AisSortBy',{attrs:{"items":[
          { value: _vm.algoliaIndices.products, label: _vm.$t('popularity') },
          { value: _vm.algoliaIndices.products + '--priceAsc', label: _vm.$t('sort-price-asc') },
          { value: _vm.algoliaIndices.products + '--priceDesc', label: _vm.$t('sort-price-desc') } ],"class-names":{
          'ais-SortBy': 'Input',
        }}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }