<template>
  <div class="SearchBar">
    <AisRangeInput attribute="price" class="SearchBar__priceRange">
      <div slot-scope="{ currentRefinement, range, refine }">
        <Slider
          ref="priceSlider"
          :value="toValue(currentRefinement, range)"
          :action="value => refine({ min: value[0], max: value[1] })"
          :min="range.min"
          :max="range.max"
          :show-inputs="false"
          :show-values-as-text="true"
          :show-tooltip="false"
          unit="kr"
          :format-type="numberInputType"
        />
      </div>
    </AisRangeInput>
    <div class="SearchBar__sortBy">
      <SortBy
        ref="sortBy"
        :hide-default="true"
        :items="sortByItems"
        :button-color="buttonColor"
        @sortChanged="onSortByChange"
      />
      <template v-if="hasRefinements">
        <Button
          v-if="['mobileS', 'mobileM', 'mobileL'].indexOf($mq) === -1"
          class="SearchBar__reset"
          :color="buttonColor"
          :size="buttonSizes.SMALL"
          :icon="buttonIcons.REMOVE"
          :disabled="false"
          @click="resetAll"
        >
          Nulstil
        </Button>
        <Button
          v-else
          class="SearchBar__reset"
          :color="buttonColors.WHITE"
          :size="buttonSizes.SMALL"
          :icon="buttonIcons.REMOVE"
          :disabled="false"
          @click="resetAll"
        >
        </Button>
      </template>
    </div>
  </div>
</template>

<script>
import { AisRangeInput } from 'vue-instantsearch';
import { SortBy } from '@components/Search';
import { InputType } from '@types/InputType';
import Slider from '@components/Search/Slider';
import settings from '@settings';
import helpers from '@helpers';
import {
  Button,
  sizes as buttonSizes,
  colors as buttonColors,
  icons as buttonIcons,
} from '@components/Buttons';
import Close from '@components/Buttons/Close';
export default {
  name: 'SearchBar',
  components: {
    AisRangeInput,
    Slider,
    SortBy,
    Button,
    Close,
  },
  props: {
    sortByItems: {
      type: Array,
      default() {
        return [
          {
            value: settings.search.indices.products,
            label: this.$t('popularity'),
          },
          {
            value: settings.search.indices.products + '--priceAsc',
            label: this.$t('sort-price-asc'),
          },
          {
            value: settings.search.indices.products + '--priceDesc',
            label: this.$t('sort-price-desc'),
          },
        ];
      },
    },
    buttonColor: {
      type: String,
      default: buttonColors.DEFAULT,
    },
  },
  data() {
    return {
      priceRefinement: false,
      sorted: false,
    };
  },
  computed: {
    hasRefinements() {
      return this.sorted || this.priceRefinement;
    },
  },
  created() {
    this.settings = settings;
    this.numberInputType = InputType.CURRENCY;
    this.buttonSizes = buttonSizes;
    this.buttonColors = buttonColors;
    this.buttonIcons = buttonIcons;
  },
  methods: {
    toValue(value, range) {
      if (value.min !== undefined || value.max !== undefined) {
        this.priceRefinement = true;
      } else {
        this.priceRefinement = false;
      }
      return helpers.algolia.rangeToValue(value, range);
    },
    onSortByChange(isDefault) {
      this.sorted = !isDefault;
    },
    resetAll() {
      this.$refs.priceSlider.reset();
      this.$refs.sortBy.reset();
    },
  },
};
</script>

<style lang="scss" scoped>
.SearchBar {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  gap: 20px 40px;
  padding: 0 15px;
}

.SearchBar__priceRange {
  flex: 1;
  max-width: 700px;
  min-width: 200px;
}

.SearchBar__sortBy {
  display: flex;
  justify-content: center;
  width: 100%;

  @media only screen and (min-width: $tablet) {
    width: auto;
    justify-content: initial;
    align-items: center;
  }
}

.SearchBar__reset {
  margin-left: 10px;
}
</style>
